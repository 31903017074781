import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  windowsButton: {
    "&:hover": {
      cursor:'pointer',
    },
    letterSpacing: '.025em',
    position: 'relative',
    borderRadius: '50px',
    borderWidth: '0px !important',
    
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top:0,
    left:0,
    zIndex:-1,
  },
  btnText: {
    fontStyle: 'normal',
    fontWeight: 600,
    textShadow: 'none',
    fontFamily: "'VT323', monospace",
  },
  txtHolder: {
    width: '100%',
    color: '#000',
  },
});

class IButton extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { classes, onClick, img } = this.props;
 
    return (
      <div onClick={onClick} className={`${classes.btnText} ${classes.windowsButton} ${this.props.className} `}>
        <img src={img} alt="button" className={classes.backgroundImage}/>
        <div className={`${classes.txtNormal}`}>{this.props.children}</div>
      </div>
    );
  }

}

export default withStyles(styles)(IButton);

import rovers from './rovers/rovers.json'

const config = {
  chainID: 1,
  infuraProvider: 'https://mainnet.infura.io/v3/036c24b52e9c45239cb0cadbf952decd',

  // based token
  basedAddress: '0x68a118ef45063051eac49c7e647ce5ace48a68a5',

  moonBaseAdddress: '0x26cF82e4aE43D31eA51e72B663d26e26a75AF729',
  basedGodAddress: '0x3ec8552ef28912fcC1cAd7068a1df73C83bd6b00',
  decimals: 18,
  defaultGasPrice: 190,

  uniswapFactory: '0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f',
  uniswapRouter: '0x7a250d5630b4cf539739df2c5dacb4c659f2488d',
  rovers:rovers,
  weth: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  susd: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
  nftAirdrop: '0x514AADe50d0A679Afd172720a7B6FBACCccB64ee',
  nftToken: '0xF9b3B38A458c2512b6680e1F3bc7A022e97D7DAb',
};

export default config;

import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";

import './i18n';
import interestTheme from './theme';

import Landing from './components/landing';
import Dashboard from './components/dashboard'

class App extends Component {


  render() {
    return (
      
      <main style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
      <MuiThemeProvider theme={ createMuiTheme(interestTheme) }>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Landing}/>
            <Route path="/app" component={Dashboard}/>
          </Switch>
        </BrowserRouter>
        </MuiThemeProvider>
      </main>
      
    );
  }
}

export default App;

import config from "../config";

import {
  CONFIGURE,
  GET_BALANCES,
  GET_ROVERS,
  GET_TOTAL_VALUE_LOCKED,
  DEPOSIT,
  WITHDRAW,
  EXIT,
  GET_APY,
  GET_PRICE,
  GET_NFT_BALANCE,
  CLAIM_NFT,
} from '../constants';

import actions from './actions.jsx';

import {
  injected,
  walletconnect,
  walletlink,
  ledger,
  trezor,
  frame,
  fortmatic,
  portis,
  squarelink,
  torus,
  authereum
} from "./connectors";

import emitter from "./emitter.jsx"

const Dispatcher = require('flux').Dispatcher;

const dispatcher = new Dispatcher();
var bigDecimal = require('js-big-decimal');
const CoinGecko = require('coingecko-api');


class Store {
  constructor() {

    this.store = {
      coingecko: new CoinGecko(),
      emitter: emitter,
      currentBlock: 0,
      moonbaseTVL: 0,
      basedPrice: 0,
      account: {},
      web3: null,
      disabled: false,
      language: 'en',
      connectorsByName: {
        MetaMask: injected,
        TrustWallet: injected,
        WalletConnect: walletconnect,
        WalletLink: walletlink,
        Ledger: ledger,
        Trezor: trezor,
        Frame: frame,
        Fortmatic: fortmatic,
        Portis: portis,
        Squarelink: squarelink,
        Torus: torus,
        Authereum: authereum
      },
      web3context: null,
      languages: [
        {
          language: 'English',
          code: 'en'
        }
      ],
      based: {
        id: 'based',
        name: 'BASED',
        address: config.basedAddress,
        abi: config.abi.erc20,//config.abi.based,
        symbol: 'BASED',
        balance: new bigDecimal('0'),
        decimals: 18,
        totalSupply: new bigDecimal('0'),
      },
      moonbase:{
        address: config.moonBaseAdddress,
        abi: config.abi.moonBase,
        balance: new bigDecimal('0'),
        basedBalance: new bigDecimal('0'),
      },
      apy: new bigDecimal('0'),
      basedGod: {
        address: config.basedGodAddress,
        abi: config.abi.basedGod,
      },
      rawRovers: {

      },
      rovers : config.rovers,
      nft: {
        abi: config.abi.nftAirdrop,
        address: config.nftAirdrop,
        balance: 0,
        tokenAddress: config.nftToken,
        tokenAbi: config.abi.nftToken,
      },
    }

    dispatcher.register(
      function (payload) {
        switch (payload.type) {
          case CONFIGURE:
            actions.configure(this, payload)
            break;
          case GET_BALANCES:
            actions.getBalances(this, false)
            break;
          case GET_ROVERS:
            actions.getRovers(this);
            break;
          case GET_TOTAL_VALUE_LOCKED:
            actions.getTVL(this);
            break;
          case DEPOSIT:
            actions.deposit(this, payload.content);
            break;
          case WITHDRAW:
            actions.withdraw(this, payload.content)
            break;
          case EXIT:
            actions.exit(this)
            break;
          case GET_APY:
            actions.updateRawRovers(this)
            break
          case GET_PRICE: 
            actions.getPrice(this, payload.content)
            break;
          case GET_NFT_BALANCE:
            actions.getNFTBalance(this)
            break
          case CLAIM_NFT:
            actions.claimNFT(this)
            break
          default: {
          }
        }
      }.bind(this)
    );
  }

  getStore(index) {
    return(this.store[index]);
  };

  setStore(obj) {
    this.store = {...this.store, ...obj}
    return emitter.emit('StoreUpdated');
  };
}

var store = new Store();

export default {
  store: store,
  dispatcher: dispatcher,
  emitter: emitter
};

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import { withNamespaces } from 'react-i18next';

import {
  Web3ReactProvider,
} from "@web3-react/core";

import { Web3Provider } from "@ethersproject/providers";

import {
  ERROR,
  CONNECTION_DISCONNECTED,
  CONNECTION_CONNECTED
} from '../../constants'

import Store from "../../stores";
import PopUp from './popup'


const emitter = Store.emitter
const store = Store.store

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '80%',
  },
  rootSmall: {
    flex: 1,
    height: 'auto',
    // display: 'flex',
    position: 'relative',
  },
  contentContainer: {
    margin: 'auto',
    textAlign: 'center',
    padding: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '95%',
    
  },
  contentContainerSmall: {
    margin: 'auto',
    textAlign: 'center',
    // padding: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  cardContainer: {
    marginTop: '60px',
    minHeight: '260px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
});

class Unlock extends Component {
  
  constructor(props) {
    super()

    this.state = {
      error: null,
      metamaskLoading: false,
      ledgerLoading: false
    }
  }

  componentDidMount() {
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.on(ERROR, this.error);
  };

  componentWillUnmount() {
    emitter.removeListener(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.removeListener(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.removeListener(ERROR, this.error);
  };

  error = (err) => {
    this.setState({error: err, metamaskLoading: false, ledgerLoading: false })
  };

  connectionConnected = () => {
    if(this.props.closeModal != null) {
      this.props.closeModal()
    }
  }

  connectionDisconnected = () => {
    if(this.props.closeModal != null) {
      this.props.closeModal()
    }
  }

  metamaskUnlocked = () => {
    this.setState({ metamaskLoading: false })
    if(this.props.closeModal != null) {
      this.props.closeModal()
    }
  }

  ledgerUnlocked = () => {
    this.setState({ ledgerLoading: false })
    if(this.props.closeModal != null) {
      this.props.closeModal()
    }
  }

  cancelLedger = () => {
    this.setState({ ledgerLoading: false })
  }

  cancelMetamask = () => {
    this.setState({ metamaskLoading: false })
  }

  render() {
    const { classes, closeModal, t, className, isSmall } = this.props;

  return(
     <div className={ `${isSmall ? classes.rootSmall : classes.root} ${className}` }>
      <div className={ isSmall? classes.contentContainerSmall : classes.contentContainer }>
        <Web3ReactProvider getLibrary={getLibrary}>
          <PopUp onConnectionClicked={onConnectionClicked} onDeactivateClicked={onDeactivateClicked} isSmall={isSmall} closeModal={ closeModal} t={t} />
        </Web3ReactProvider>
      </div>
    </div>
  )

  }
}

function getLibrary(provider) {

  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}

function onConnectionClicked(currentConnector, name, setActivatingConnector, activate) {
  const connectorsByName = store.getStore('connectorsByName')
  setActivatingConnector(currentConnector);
  activate(connectorsByName[name]);
}

function onDeactivateClicked(deactivate, connector) {
  if(deactivate) {
    deactivate()
  }
  if(connector && connector.close) {
    connector.close()
  }
  store.setStore({ account: { }, web3context: null })
  emitter.emit(CONNECTION_DISCONNECTED)
}


export default withNamespaces()(withRouter(withStyles(styles)(Unlock)));

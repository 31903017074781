import development from "./development.config";
import production from "./production.config";
import abi from "./abi.js";

const env = process.env.APP_ENV || 'production';

const config = {
  development,
  production
};

const conf = config[env];
conf.abi = abi;


export default config[env];

import async from 'async';

import config from "../config";


const rp = require('request-promise');
var bigDecimal = require('js-big-decimal');


const getERC20Balance = async (web3, asset, account, callback) => {
	let erc20Contract = new web3.eth.Contract(config.abi.erc20, asset.address)

	try {
		let balance = await erc20Contract.methods.balanceOf(account.address).call({});
		callback(null, new bigDecimal(balance))
	} catch(ex) {
		return callback(ex)
	}
}


const getTotalSupply = async (web3, asset, account, callback) => {
	let erc20Contract = new web3.eth.Contract(config.abi.erc20, asset.address)

	try {
		let balance = await erc20Contract.methods.totalSupply().call({});
		callback(null, new bigDecimal(balance))
	} catch(ex) {
		return callback(ex)
	}
}

const getRoverToken = async (web3, account, callback) => {
	//TODO 
	//Get the token address and then if it is known, populate with details on that token
	callback(null,  {
						name: 'basedConnect',
						logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x68A118Ef45063051Eac49c7e647CE5Ace48a68a5/logo.png',
						address: '0x68A118Ef45063051Eac49c7e647CE5Ace48a68a5'
					})
}

const getMoonbaseTVL = async (web3, account, moonbase, callback) => {
	//TODO
	callback(null, 5)
}

const getGasPrice = async (store) => {
	try {
		const url = 'https://gasprice.poa.network/'
		const priceString = await rp(url);
		const priceJSON = JSON.parse(priceString)
		if(priceJSON) {
			return priceJSON.fast.toFixed(0)
		}
		return config.defaultGasPrice
	} catch(e) {
		console.log(e)
		return config.defaultGasPrice
	}
}

const checkApproval = async (web3, asset, account, amount, contract, callback) => {
	try {
		const erc20Contract = new web3.eth.Contract(asset.abi, asset.address)
		const allowance = await erc20Contract.methods.allowance(account.address, contract).call({ from: account.address })
		const ethAllowance = web3.utils.fromWei(allowance, "ether")

		if(parseFloat(ethAllowance) < parseFloat(amount)) {
			console.log('approval reached')
			erc20Contract.methods.approve(contract, web3.utils.toWei("99999999999999999999999", "ether"))
			.send({ from: account.address, gasPrice: web3.utils.toWei(await getGasPrice(), 'gwei') })
			.on('transactionHash', function(hash){callback()})
			.on('error', function(error) {
				if (!error.toString().includes("-32601")) {
					if(error.message) {
						return callback(error.message)
					}
					callback(error)
			  	}
	  		})
	  	}else{
	  		callback()
	  	}
	} catch(error) {
		console.log(error)
		if(error.message) {
			return callback(error.message)
		}
		callback(error)
	}
}

const getMoonbaseBalance = async (web3, moonbase, account, callback) => {
	try{
		const moonbaseContract = new web3.eth.Contract(moonbase.abi, moonbase.address)
		let balance = new bigDecimal(await moonbaseContract.methods.balanceOf(account.address).call({from:account.address}))
		let conversion = new bigDecimal(await moonbaseContract.methods.getPricePerFullShare().call({from:account.address}))

		callback(null, {based:balance.multiply(conversion),token:balance})
	}catch(err) {
		console.error(err)
		callback(err)
	}
}

const zeros = (cnt)=> {
	let out = ""
	for(let i = 0; i < cnt; i++) {
		out += "0"
	}
	return out
}

const uniswapPath = async (web3, token0, token1, callback) => {
	
	const uniswapFactory = new web3.eth.Contract(config.abi.uniswapV2Factory, config.uniswapFactory)
	
	const weth = config.weth
	const susd = config.susd
	// Optimized case
	let pairs = await async.parallel([ 
		(cbInner) => {
			uniswapFactory.methods.getPair(token0, token1).call({},cbInner)
		},
		(cbInner) => {
			uniswapFactory.methods.getPair(token0, susd).call({},cbInner)
			
		},
		(cbInner) => {
			uniswapFactory.methods.getPair(token0, weth).call({},cbInner)
		},
	])
	 
	if(pairs[0] !== '0x0000000000000000000000000000000000000000') {
		callback(null, [token0, token1])
		return
	}
	if(pairs[1] !== '0x0000000000000000000000000000000000000000') {
		callback(null, [token0, susd, token1])
		return
	}
	if(pairs[2] !== '0x0000000000000000000000000000000000000000') {
		callback(null, [token0, weth, token1])
		return
	}
	console.log("no easy solutions found!")
	// No easy solutions found
	callback("TODO: Add the path finding algorithm")
		
}

const fetchUniswapPairs = async (web3, path, callback) => {

	const uniswapFactory = new web3.eth.Contract(config.abi.uniswapV2Factory, config.uniswapFactory)
	
	let fns = []
	for(let i = 0; i < path.length - 1; i++) {
		fns.push((cbInner) => {
			uniswapFactory.methods.getPair(path[i], path[i+1]).call({},cbInner)
		})
	}
	async.parallel(fns, callback)

}


const getPriceInBased = async (web3, amount, token, callback) => {
	const basedAddress = config.basedAddress
	uniswapPath(web3, token, basedAddress, (err, path) => {
		if(err) {
			console.error(err)
			callback(err)
			return
		}
		fetchUniswapPairs(web3, path, async (err, pairs)=>{
			if(err) {
				console.error(err)
				callback(err)
				return
			}
			let amountA = amount
			let tokenA = token
			for(let i = 0; i < pairs.length; i++){
				let pair = pairs[i]
				let pairContract = new web3.eth.Contract(config.abi.uniswapV2Pair, pair)
				let reserves = await pairContract.methods.getReserves().call({})
				let reservesA = undefined
				let reservesB = undefined
				if(tokenA === await pairContract.methods.token0().call({})) {// starting with token0
					reservesA = new bigDecimal(reserves[0])
					reservesB = new bigDecimal(reserves[1])
					tokenA = await pairContract.methods.token1().call({})
				}else{
					reservesA = new bigDecimal(reserves[1])
					reservesB = new bigDecimal(reserves[0])
					tokenA = await pairContract.methods.token0().call({})
				}
				// naive price calculation
				amountA = amountA.multiply(reservesB).divide(reservesA,0)
			}
			let pairContract = new web3.eth.Contract(config.abi.erc20, basedAddress)
			let decimals = await pairContract.methods.decimals().call({})
			let out = amountA.divide(new bigDecimal(10**decimals))
			callback(null, out)


		})
	})
}

const SECONDS_IN_YEAR = new bigDecimal('31536000')
const ZERO = new bigDecimal('0')

const _getRoverYields = async (web3, rover, callback) => {
	const roverContract = new web3.eth.Contract(config.abi.rover, rover)
	let details = undefined;
	try{
		details = await async.parallel([ 
			(cbInner) => {
				roverContract.methods.rewardToken().call({},cbInner)
			},
			(cbInner) => {
				roverContract.methods.roverStart().call({},cbInner)
			},
		])
	}catch(e) {
		console.error(e)
		callback(e)
		return
	}
	
	const rewardToken = details[0]
	const roverStart = new bigDecimal(details[1])
	if(roverStart.getValue() === "0") { // 0% APY if rover has not started
		callback(null, {yearly: ZERO,balance: ZERO,timeLeft: ZERO, })
		return
	}

	const erc20Contract = new web3.eth.Contract(config.abi.erc20, rewardToken)
	const rewardBalance = new bigDecimal(await erc20Contract.methods.balanceOf(rover).call({}));
	if(rewardBalance.getValue() === "0") { // 0% APY if there are no tokens
		callback(null, {yearly: ZERO,balance: ZERO,timeLeft: ZERO, })
		return
	}
	const moreDetails = await async.parallel([
		(cbInner) => {
			getPriceInBased(web3,rewardBalance,  rewardToken, cbInner)
		},
		(cbInner) => {
			roverContract.methods.vestingTime().call({}, cbInner)
		},
		(cbInner) => {
			let erc20Contract = new web3.eth.Contract(config.abi.erc20, rewardToken)
			erc20Contract.methods.decimals().call({},cbInner)
		}
	])

	const totalPrice = moreDetails[0]
	const vestingTime = new bigDecimal(moreDetails[1])
	const rewardTokenDecimals = moreDetails[2]
	const elapsedTime = new bigDecimal(Math.floor(Date.now() / 1000)).subtract(roverStart)
	let timeLeft = vestingTime.subtract(elapsedTime)
	if(timeLeft.getValue() === "0") {
		callback(null, {yearly: ZERO,balance: ZERO,timeLeft: ZERO, })
		return
	}
	callback(null, {
		yearly: totalPrice.multiply(SECONDS_IN_YEAR).divide(timeLeft),
		balance: rewardBalance.divide(new bigDecimal(10**rewardTokenDecimals)),
		timeLeft: timeLeft, 
	})
}

const getRoverYields = async (web3, rover, callback) => {
	if(callback) {
		_getRoverYields(web3, rover, callback)
		return
	}
	return new Promise((resolve, reject)=>{
		_getRoverYields(web3, rover, (err, res)=>{
			if(err) {
				reject(err)
			}else{
				resolve(res)
			}
		})
	})
}

const getRovers = async (web3, basedGod, callback) =>{
	const basedGodContract = new web3.eth.Contract(config.abi.basedGod, config.basedGodAddress)

	if(callback) {
		basedGodContract.methods.getRovers().call({}, callback)
		return
	}
	return new Promise((resolve, reject)=>{
		basedGodContract.methods.getRovers().call({}, (err, res)=>{
			if(err) {
				reject(err)
			}else{
				resolve(res)
			}
		})
	})
	
}
export default {
	getERC20Balance,
	getRoverToken,
	getMoonbaseTVL,
	getGasPrice,
	checkApproval,
	getMoonbaseBalance,
	zeros,
	getTotalSupply,
	uniswapPath,
	getPriceInBased,
	getRoverYields,
	getRovers,
};
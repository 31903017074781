import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  windowsButton: {
    //transition: 'all 300ms ease 0ms',
    "&:hover": {
      cursor:'pointer',
    },
    letterSpacing: '.025em',
    position: 'relative',
    borderRadius: '50px',
    borderWidth: '0px !important',
    
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top:0,
    left:0,
    zIndex:-1,
  },
  btnText: {
    fontStyle: 'normal',
    fontWeight: 600,
    textShadow: 'none',
    fontFamily: "'VT323', monospace",
    fontSize: '3em',
  },
  txtHolder: {
    width: '100%',
    textAlign: 'center',
  },
  txtNormal: {
    color: '#68066b',
  },
  txtGrey: {
    color: '#484848',
    "&:hover": {
      cursor:'not-allowed',
    },
  }
});

class WButton extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { classes, onClick, disabled } = this.props;
 
    return (
      <div onClick={disabled ? ()=>{} : onClick} className={`${classes.btnText} ${classes.windowsButton} ${this.props.className} `}>
        <img src={disabled? '/btn-grey.png' : '/btn.png'} alt="button" className={classes.backgroundImage}/>
        <div className={`${classes.txtHolder} ${disabled? classes.txtGrey : classes.txtNormal}`}>{this.props.children}</div>
      </div>
    );
  }

}

export default withStyles(styles)(WButton);

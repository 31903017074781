import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
	actionText: {
		"&:hover": {
			cursor:'pointer',
		},
	},
	sidePanel: {
		backgroundColor: "#272125",
		padding: '1.1% 0.6% 0 0.6%',
		borderRadius: '4px',
	},
	sidePanelScreen: {
		backgroundColor: "#000000",
		width: '95%',
		height: '85%',
		margin: 'auto',
		padding: '5px 5px 0 5px',
	},
	tvl: {
		textAlign: 'right',
		marginRight: '1.5%',
		marginTop: 0,
		marginBottom: 0,

	},
	text: {
		color:'#aaa',
	},
	rovera: {
	},
	roverThFirst: {
		textAlign: 'left',
		borderBottom: '1px dashed #aaa',
		fontWeight: 500,
	},
	roverTh: {
		textAlign: 'center',
		borderBottom: '1px dashed #aaa',
		fontWeight: 500,
	},
	roverImg: {
		width: '14%',
		verticalAlign: 'text-top',
		marginRight:'1%'	
	},
	roverTd: {
	},
	roverTable: {
		margin: 'auto',
		width: '95%',
	},
	roverTdRight: {
		textAlign: 'center',
		width: '30%'
	},
	roverTHead: {
		
	},
	bottomLogo: {
		display: 'block',
		width: '75%',
		margin: 'auto',
		marginTop: '3%',
		marginBottom: '3%',
	},
	tvlHolder: {
		marginTop: '3%'
	}
});


class SidePanel extends Component {
	constructor(props) {
		super();
	}

	render() {
		const { classes, className, width, height } = this.props;

		return (
			<div style={{width:width, height: height}} className={`${classes.sidePanel} ${className}`}>
				<div className={classes.sidePanelScreen}>
				{this._renderTVL()}
				{this._renderRovers()}
				
				</div>
				<img className={classes.bottomLogo} alt='silver moonbase' src='/mbmetal.png'/>
			</div>
		);
	}
	
	_renderTVL() {
		const { classes } = this.props;
		const { textFontSize } = this.props;
		return(
			<div className={classes.tvlHolder}>
			<p style={{fontSize:textFontSize}} className={`${classes.tvl} ${classes.text}`}>Total Value Locked:</p>
			<p style={{fontSize:textFontSize}} className={`${classes.tvl} ${classes.text}`}>{(this.props.tvl).toFixed(2)} $BASED</p>
			</div>
		)
	}

	_renderRovers() {
		const { classes } = this.props;
		const { titleFontSize } = this.props;
		return(
			<table className={`${classes.roverTable}`}>
			<thead className={`${classes.roverTHead}`}>
				<tr className={`${classes.roverTr}`}>
					<th style={{fontSize:titleFontSize}} className={`${classes.roverThFirst} ${classes.text}`} >Farming</th>
					<th style={{fontSize:titleFontSize}} className={`${classes.roverTh} ${classes.text}`} >Vesting</th>
				</tr>
			</thead>
			{this._renderRoverRows()}
			</table>
		)
	}

	_renderRoverRows() {
		const { textFontSize, classes, roversData, rovers} = this.props;
		if(!rovers) {
		 return (<tbody></tbody>)
		}
		let sortedRovers = rovers.sort((a, b)=>{
			let priceA = roversData[a.rover.toLowerCase()]
			let priceB = roversData[b.rover.toLowerCase()]
			if((!priceA && !priceB) || (!a && !b) ) {
				return 0
			}
			if (!a && priceB && priceB.yearly) {
				return 0-parseFloat(priceB.yearly.getValue())
			}
			if (!b && priceA && priceA.yearly) {
				return 0-parseFloat(priceA.yearly.getValue())
			}
			if(!priceA || !priceB || !priceA.yearly || !priceB.yearly) {
				return 0
			}
			return parseFloat(priceA.yearly.getValue()) - parseFloat(priceB.yearly.getValue())
		});
		return (<tbody>{sortedRovers.map((rover)=>{
			const amount = roversData[rover.rover.toLowerCase()]
			console.log({amount})
			return (
				<tr key={rover.rover} className={classes.roverTr}>
					<td style={{fontSize:textFontSize}} className={`${classes.roverTd} ${classes.text}`}>
						<a href={rover.url} className={`${classes.rovera} ${classes.text}`}>
							{rover.name.substr(0,14)}
							
						</a>
					</td>
					<td style={{fontSize:textFontSize}} className={`${classes.roverTdRight} ${classes.roverTd} ${classes.text}`}>
					{amount ? amount.balance.getValue() : "..."}
					</td>
				</tr>
			)
		})}</tbody>)
	}



	_scene(scene) {
		this.setState({scene:scene})
	}
}

export default withStyles(styles)(SidePanel);
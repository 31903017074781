import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  textinput:{
    backgroundColor: 'transparent',
    borderWidth: '0 0 1px 0',
    color: '#FFF',
  }
});

class BTextInput extends Component {
  constructor(props) {
    super();

  }

  render() {
    const { classes, className } = this.props;

    return (
      <input
          className={`${classes.textinput} ${className}`}
          type="text"
          onChange={this.props.onChange}
          value={this.props.value}
          />
    );
  }
}

export default withStyles(styles)(BTextInput);

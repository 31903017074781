import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
 	dash: {
 		position: 'absolute',
 		width:'100%',
 		bottom: 0,
 	},
 	leftDial: {
 		position: 'absolute',
 		left: '2%',
 		bottom: '4.4vw',
 		zIndex: 1,
 		width: '20%',
 	},
 	rightDial: {
 		position:'absolute',
 		right: '3%',
 		bottom: '4.4vw',
 		zIndex: 1,
 		width: '20%',
 	},
 	leftNeedle: {
 		position:'absolute',
 		zIndex: 2,
 		bottom: '2.5vw',
 		left:'11.8%',
 		height: '16vw',
 	},
 	rightNeedle: {
 		position:'absolute',
 		zIndex: 2,
 		bottom: '2.6vw',
 		right:'12.6%',
 		height: '16vw',
 	}
});


class BackBoard extends Component {
	constructor(props) {
		super();
	}

	render() {
		const { classes, className, rightDial, leftDial } = this.props;


		return (
			<div className={`${className}`}>
				<img src='/dash.png' alt='dash' className={`${classes.dash}`} />
				<img src='/dial1.png' alt='dial' className={`${classes.leftDial}`}/>
				<img src='/spped.png' alt='dial2' className={`${classes.rightDial}`}/>
				{leftDial && this.renderNeedle(leftDial.min,leftDial.max,leftDial.val,classes.leftNeedle)}
				{rightDial && this.renderNeedle(rightDial.min,rightDial.max,rightDial.val,classes.rightNeedle)}
			</div>	
		);
	}

	renderNeedle(min,max,val,className) {
		const theta = 120
		const alpha = 120
		let ratio = (val-min)/(max-min)
		let omega = ratio * (theta+alpha)
		let rotation = omega - theta
		return (
			<img style={{transform:'rotate('+rotation+'deg)'}} alt='needle' src='/needle.png' className={className}/>
		)
	}

	getBasedLevel() {
		if(!this.props.account) {
			return -1
		}
		return parseInt(this.props.account.address.substr(0,8)) % 69
	}

	getAccountName() {
		const { account } = this.props
		if(!account) {
			return "No Account"
		}
		return account.address.substr(0,5)+"..."+account.address.substr(-3)
	}
	
}

export default withStyles(styles)(BackBoard);
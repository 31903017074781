import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {
  WButton,
} from '../pieces';

const styles = theme => ({
  heroLogo: {
    display: 'block',
    width: '100%',
    maxWidth: '1150px',
    margin: 'auto',
    marginTop: '0px',
    justifyContent: "center",
    alignItems: "center"
  },
  transmission: {
    display: 'block',
    width: '100%',
    maxWidth: '1150px',
    margin: 'auto',
    marginTop: '150px',
    marginBottom: '150px',
    justifyContent: "center",
    alignItems: "center"
  },
  consoleButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  background: {
    position: "fixed",
    width: '100%',
    height: '100%',
    backgroundImage: "url(/space.png)",
    zIndex: '-20',
    left: '0',
    top: '0',
  },
  footer: {
    width: '100vw',
    margin: 'auto',
    marginTop: '3%',
    justifyContent: "center",
    marginBottom: '-6px'
  },
  boxLeft: {
    marginLeft: '120px',
    width: '100%',
    margin: '50px auto',
    background: 'black',
    padding: '20px 40px ',
    textAlign: 'left',
    fontWeight: '900',
    color: "#fff",
    position: "relative",
    borderRadius: "25px",
    border: '1px solid #ffffff'
  },
  boxRight: {
    marginRight: '100px',
    width: '100%',
    margin: '50px auto',
    background: 'black',
    padding: '20px 40px ',
    textAlign: 'left',
    fontWeight: '900',
    color: '#fff',
    position: 'relative',
    borderRadius: "25px",
    border: '1px solid #ffffff'
  },
  avatarLeft: {
    maxWidth: '120px',
    float: 'left'
  },
  chatRow: {
    maxWidth: '960px',
    margin: 'auto'
  },
  avatarRight: {
    maxWidth: '120px',
    float: 'right'
  },
  g: {
    color: '#7bbe94'
  },
  timeStampLeft: {
    color: ' #999999',
    textAlign: 'right'
  },
  avBgR7: {
    backgroundImage: "url(/Ghoul-311-Find-Out.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    paddingRight: '120px' 
  },
  avBgL7: {
    backgroundImage: "url(/rogue1.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'Left',
    paddingRight: '120px' 
  },  
   avBgR6: {
    backgroundImage: "url(/mysterious-ghoul.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    paddingRight: '120px' 
  },
  avBgL6: {
    backgroundImage: "url(/MSTRWCKD.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'Left',
    paddingRight: '120px' 
  },    
   avBgR5: {
    backgroundImage: "url(/unit-g.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    paddingRight: '120px' 
  },
  avBgL5: {
    backgroundImage: "url(/greebohx.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'Left',
    paddingRight: '120px' 
  },    
  avBgR4: {
    backgroundImage: "url(/robertson.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    paddingRight: '120px' 
  },
  avBgL4: {
    backgroundImage: "url(/Lord-Epsilon.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'Left',
    paddingRight: '120px' 
  },    
  avBgR3: {
    backgroundImage: "url(/astaria.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    paddingRight: '120px' 
  },
  avBgL3: {
    backgroundImage: "url(/Avatar-3-L.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'Left',
    paddingRight: '120px' 
  },    
  avBgR2: {
    backgroundImage: "url(/Avatar-4-R.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    paddingRight: '120px' 
  },
  avBgL2: {
    backgroundImage: "url(/Avatar-1-L.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'Left',
    paddingRight: '120px' 
  },    
  avBgR: {
    backgroundImage: "url(/captain-chad.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    paddingRight: '120px' 
  },
  avBgL: {
    backgroundImage: "url(/Avatar-2-L.png)",
    backgroundSize: '120px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'Left',
    paddingRight: '120px' 
  }    
})

class Landing extends Component {
  constructor(props) {
    super()
    document.getElementsByTagName("html")[0].style['overflow']="scroll"
    document.getElementsByTagName("html")[0].style['overflow-y']="scroll"
    document.getElementsByTagName("html")[0].style['overflow-x']="hidden"
  }

  render() {
    const {classes} = this.props;
    return(
      <Container>
        <div className={classes.background} />
        <img className={classes.heroLogo} src='/moonbase-hero.png' alt="MoonBase logo" />
        <Row>
          <Col className={classes.consoleButtons}>
            <WButton classname="consoleButtons"onClick={()=>{this.gotoApp()}}>LAUNCH APP</WButton>
          </Col>
        </Row>  
    <Row className={classes.chatRow}>
      <img className={classes.transmission} src='/transmission.gif' alt="Transmission Intercepted" />
    </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL}>
          <div className={classes.boxLeft}>
            <p style={{ color:"purple" }}> Abernathy's Spectre: </p>
            <p> Um, ser, pardon me, but wtf is moonbase?</p>
            <p className = {classes.timeStampLeft}> 4:20 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR}>
          <div className={classes.boxRight}>
            <p style={{ color:"green" }}> Space Commander Chad: </p>
            <p>Well, it's a new DeFi primitive built by GHOULS and designed to encourage alignment, growth, and collaboration between fair-launch projects.</p>
            <p className = {classes.timeStampLeft}> 4:20 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL}>
          <div className={classes.boxLeft}>
            <p style={{ color:"purple" }}> Abernathy's Spectre: </p>
            <p>How does it do that?</p>
            <p className = {classes.timeStampLeft}> 4:20 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR}>
          <div className={classes.boxRight}>
            <p style={{ color:"green" }}> Space Commander Chad: </p>
            <p>Over the past month, we’ve all been farming a shit ton of different food coins, right? Like I had to join every one of those communities to get mega yields. </p>
            <p className = {classes.timeStampLeft}> 4:20 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL3}>
          <div className={classes.boxLeft}>
            <p style={{ color:"red" }}> Qwaarth: </p>
            <p>Yeah, same, but I got rekt on Sake and I’m still holding my bags.</p>
            <p className = {classes.timeStampLeft}> 4:21 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR4}>
          <div className={classes.boxRight}>
            <p style={{ color:"cyan" }}> Robertson: </p>
            <p>
              LOL, you pleb. I immediately dumped Sake as soon as I got it last week. My IQ is high even though I am an experiment beyond what the mind-control government wanted in the laguna beach lab in which I was first formed around the Vietnam era. My brain was added to this bear’s body.
            </p>
            <p className = {classes.timeStampLeft}> 4:21 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR3}>
          <div className={classes.boxRight}>
            <p style={{ color:"pink" }}> Astaria: </p>
            <p> Okay, thanks for that Robertson… so before Moonbase, you had those two options. You either keep your tokens and stay in that community or you farm-dump and move on like a yVault. 
            </p>
            <p className = {classes.timeStampLeft}> 4:22 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL2}>
          <div className={classes.boxLeft}>
            <p style={{ color:"orange" }}> Mister Big: </p>
            <p> Classic cope vs Chad scenario. </p>
            <p className = {classes.timeStampLeft}> 4:22 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR}>
          <div className={classes.boxRight}>
            <p style={{ color:"green" }}> Space Commander Chad: </p>
            <p>Good observation, Mr. Big, but Moonbase presents a totally different system. Imagine a new DeFi primitive that incentivizes all of those food coins, and other projects, to come together and collaborate, forming an ultra-alpha ecosystem of fair-launch projects. </p>
            <p className = {classes.timeStampLeft}> 4:23 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR2}>
          <div className={classes.boxRight}>
            <p className={classes.g}> Morgoth The Mutilator: </p>
            <p> 
              Like a shitcoin version of the Avengers? I despise the Avengers and foiled their attempts to protect Earth from becoming the beautiful VC Wasteland it is today. I am the lord of premine and I will capture you fools soon enough.
            </p>
            <p className = {classes.timeStampLeft}> 4:23 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR}>
          <div className={classes.boxRight}>
            <p style={{ color:"green" }}> Space Commander Chad: </p>
            <p>How did Morgoth get in here? Well, anyways it’s more like an alliance of fair-launch projects that are actually decentralized.</p>
            <p className = {classes.timeStampLeft}> 4:23 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL4}>
          <div className={classes.boxLeft}>
            <p style={{ color:"teal" }}> Lord Epsilon: </p>
            <p>Ok... so this is a high-IQ rug pull or what? How does it work?<br />
            <br />
            Hi everyone btw, I am an aristocrat from the Aptolia galaxy. Just trying to get info so I can reclaim my birthright.</p>
            <p className = {classes.timeStampLeft}> 4:25 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR6}>
          <div className={classes.boxRight}>
            <p style={{ color:"pink" }}> Mysterious Ghoul: </p>
            <p>
            I appear! So, Moonbase itself is kind of like an index fund or Balancer pool, but not really.<br />
            <br /> 
            You deposit your $BASED into Moonbase and in return, you get mbBASED. mbBASED is a standard ERC-20 token that represents your share of TVL in the Moonbase.<br />
            <br />
            All of the complexities of rebasing are handled within the Moonbase, so this provides a new layer of composability for the $BASED token itself.
            </p>
            <p className = {classes.timeStampLeft}> 4:25 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL5}>
          <div className={classes.boxLeft}>
           <p style={{ color:"orange" }}> Greebohx: </p>
            <p> Uuh, wdym, we insectoids have a consciousness that exists on its own quantum space crypto block chain and we don’t care about composability. Number go up? We are Greebohx. We are many. </p>
            <p className = {classes.timeStampLeft}> 4:26 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR6}>
          <div className={classes.boxRight}>
            <p style={{ color:"pink" }}> Mysterious Ghoul: </p>
            <p>
              DeFi is built out of all these crazy Money LEGO® bricks. These LEGO® bricks fit together because they conform to the ERC-20 standard, but as we build new systems, each iteration becomes gnarlier than the last.<br /> 
              <br />
              Like, $BASED is a non-standard ERC-20 token because of its elastic supply properties. This completely smashes the normie DeFi LEGO® paradigm, which makes it a lot harder for other projects to integrate and build stuff with $BASED. 
              mbBASED is a standard ERC-20 token, so it’s compatible with all major DeFi protocols.  
            </p>
            <p className = {classes.timeStampLeft}> 4:27 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL7}>
          <div className={classes.boxLeft}>
            <p className={classes.g}> Rogue One: </p>
            <p> Money Erector Set > Money LEGO®
            <img alt='lego' width="600px" src="/lego.jpg"/>
            </p>
            <p className = {classes.timeStampLeft}> 4:28 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR5}>
          <div className={classes.boxRight}>
            <p style={{ color:"pink" }}> Unit G: </p>
            <p>
              I get it. As a $BASED holder, it’s a huge pain in the ass to stake my $BASED in a pool or send it somewhere because I have to make sure that whatever I’m sending it to supports rebasing.<br />
              <br />
              But now, with mbBASED, I don’t have to worry about this anymore and I can do whatever I want with it.<br /> 
              <br />
              Is that right?
            </p>
            <p className = {classes.timeStampLeft}> 4:28 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL3}>
          <div className={classes.boxLeft}>
            <p style={{ color:"red" }}> Qwaarth: </p>
            <p> Yep, and you can follow the price of mbBASE the same way you would with any other token. So on CoinGecko, the price movements will be closer to what people expect of standard token assets and makes it easier for people who don’t get the nuances of rebasing people to follow the charts. </p>
            <p className = {classes.timeStampLeft}> 4:29 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR7}>
          <div className={classes.boxRight}>
            <p style={{ color:"green" }}> Ghoul 311 Find Out: </p>
            <p>Alright, that actually sounds bad ass, but you said this was meant to promote community collaboration. <br />
            <br />
            How does it do that? I am forced to do community service picking up trash around Laguna Beach.<br />
            <br />
            Is it like that?</p>
            <p className = {classes.timeStampLeft}> 4:30 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR6}>
          <div className={classes.boxRight}>
            <p style={{ color:"pink" }}> Mysterious Ghoul: </p>
            <p>
              That’s where Rovers come in. These are the next components in Moonbase. Rovers are complex farming instruments and any project can deploy their own custom Rover.        
            </p>
            <p className = {classes.timeStampLeft}> 4:30 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR7}>
          <div className={classes.boxRight}>
            <p style={{ color:"green" }}> Ghoul 311 Find Out: </p>
            <p>Bro, I'm too low IQ to understand anything you just said. What does a Rover do?</p>
            <p className = {classes.timeStampLeft}> 4:30 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR6}>
          <div className={classes.boxRight}>
            <p style={{ color:"pink" }}> Mysterious Ghoul: </p>
            <p>
              <img alt='dump' src="/dump.jpg"/>
            </p>
            <p className = {classes.timeStampLeft}> 4:31 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR3}>
          <div className={classes.boxRight}>
            <p style={{ color:"pink" }}> Astaria: </p>
            <div> 
              <p>Projects offer tokens to the $BASED community by depositing them into the Rover. They can do this however they want, whether it’s through yield farming, from their treasury, or whatever.
              The tokens that the Rover receives are locked in a 1-year linear vesting schedule.</p>

              <p>Every day, a small amount of the tokens that are locked up in the Rover can be swapped out for $BASED tokens through Uniswap. The amount of tokens that can be swapped accumulates over time until someone calls rugPull on the Rover.
              rugPull is a public function that anyone can call at any time. Whoever calls the rugPull function has to pay the gas fees and in exchange, they get 1% of the total amount that’s swapped in $BASED. </p>
              <p>The rest of the $BASED is sent to the Moonbase and distributed proportionally among all mbBASED token holders.</p>

              <img alt='flow-chart' src="/flow_chart.jpg"/>
              <img alt='graph' width="600px" src="/graph.jpg"/>
            </div>
            <p className = {classes.timeStampLeft}> 4:31 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL3}>
          <div className={classes.boxLeft}>
            <p style={{ color:"red" }}> Qwaarth: </p>
            <p> And you’re telling me that any project can just permissionlessly deploy their own Rover and integrate with the $BASED community?  </p>
            <p className = {classes.timeStampLeft}> 4:32 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR}>
          <div className={classes.boxRight}>
            <p style={{ color:"green" }}> Space Commander Chad: </p>
            <p>
              Sure, but there’s a bit more to it than that. <br />
              <br />
              If you really want your project to be certified $BASED, you need to submit a proposal to the $BASED snapshot page.<br />
              <br /> 
              The proposal only needs to ask one simple yes or no question: Is this BASED?<br />
              <br />
              In the body of the proposal, describe your project, vision, and purpose, along with any relevant links. If the community deems it to be BASED, then it shall be so. 
              <br />
              <br />

             On the other hand, it’s your life and you don’t actually have to do any of that. You can just launch a Rover and integrate, but is that really BASED? 
            </p>
            <p className = {classes.timeStampLeft}> 4:32 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL7}>
          <div className={classes.boxLeft}>
            <p className={classes.g}> Rogue One: </p>
            <p> Okay, so what’s the point? What problems does this address? I woke up in some kind of video game thing. </p>
            <p className = {classes.timeStampLeft}> 4:32 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR2}>
          <div className={classes.boxRight}>
            <p className={classes.g}> Morgoth The Mutilator: </p>
            <p>
            One of the problems is that new projects need to build an authentic community of early adopters quickly in order to sustainably scale. This can be one of the hardest obstacles to overcome for new projects, especially for fair-launch projects that don't have the funding to compete with larger, VC-backed ones.<br />
            Like the ones of my VC legion that laid waste to the earth and all of its natural DeFi resources.<br />
            <br />
            The other problem is that yVaults are designed to farm-dump, which is great for YFI holders, but not so great for the communities that are getting dumped on.<br />
            <br />
            Don't get me wrong, Yearn is revolutionary, but if you want to actually build an ecosystem of fair-launch projects, you need more collaborative systems. I will be here to stop you. For I am Morgoth. From destruction comes beauty. 
            </p>
            <p className = {classes.timeStampLeft}> 4:32 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL3}>
          <div className={classes.boxLeft}>
            <p style={{ color:"red" }}> Qwaarth: </p>
            <p> Can mods do something about the spacelord tyrant in our channel?<br />
            <br /> 
            Alright, so how does Moonbase help solve these problems? </p>
            <p className = {classes.timeStampLeft}> 4:33 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR3}>
          <div className={classes.boxRight}>
            <p style={{ color:"pink" }}> Astaria: </p>
            <p>
              Socially, by integrating with Moonbase, projects can join forces with the $BASED community, which is obviously the best and most alpha of all communities in DeFi, forming a symbiotic relationship.
              <br />
              <br />
             Since $BASED holders will directly benefit from the success of a partner project, they will work together to help that project succeed.
            </p>
            <p className = {classes.timeStampLeft}> 4:33 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL3}>
          <div className={classes.boxLeft}>
            <p style={{ color:"red" }}> Qwaarth: </p>
            <p> Hmmm… so community alignment through economic alignment... </p>
            <p className = {classes.timeStampLeft}> 4:33 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR}>
          <div className={classes.boxRight}>
            <p style={{ color:"green" }}> Space Commander Chad: </p>
            <p>Like a mechanical turk that's designed to promote collaboration.</p>
            <p className = {classes.timeStampLeft}> 4:35 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgR4}>
          <div className={classes.boxRight}>
            <p style={{ color:"cyan" }}> Robertson: </p>
            <p>
              <img alt='yes' src="/yes.jpg"/>
            </p>
            <p className = {classes.timeStampLeft}> 4:35 AM </p>
          </div>
        </div>  
      </Row>

      <Row className={classes.chatRow}>
        <div className={classes.avBgL3}>
          <div className={classes.boxLeft}>
            <p style={{ color:"red" }}> Qwaarth: </p>
            <p>
            So let me summarize all this…<br /> 
            <br />
            Moonbase benefits $BASED holders by increasing demand for $BASED and holding supply in a sink.<br />
            <br />
            It creates a whole new market through mbBASED tokens and rewards mbBASED holders with yield from Rovers.<br />
            <br />
            AND it benefits the projects that partner with $BASED by economically aligning communities and turning tribalism into a homie hangout.

            </p>
            <p className = {classes.timeStampLeft}> 4:36 AM </p>
          </div>
        </div>  
      </Row>
      
      <Row className={classes.chatRow}>
        <div className={classes.avBgR5}>
          <div className={classes.boxRight}>
            <p style={{ color:"pink" }}> Unit G: </p>
            <p> 
              Turning jealousy into comradery among peers. Like how the psychedelic substance that my skin secretes that only works on humans brings humans together.<br /> 
              <br />
              In perfect harmony.
            </p>
            <p className = {classes.timeStampLeft}> 4:236 AM </p>
          </div>
        </div>  
      </Row>
      
      <div className={classes.background} />
        <img className={classes.footer} src='/moonbase-base.png' alt="Welcome to the future of DeFi" />
​
      </Container>
    )
  }

  gotoApp() {
    this.props.history.push('/app')
  }
}

export default withRouter(withStyles(styles)(Landing));

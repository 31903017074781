// Rinkeby config
const config = {
	chainID: 4,
	infuraProvider: 'https://rinkeby.infura.io/v3/036c24b52e9c45239cb0cadbf952decd',

	// based token
	basedAddress: '0xc3dbf84Abb494ce5199D5d4D815b10EC29529ff8',

	moonBaseAdddress: '0x1444E2B1d2Fb716Ea46d39f880326ae3dC66c9c1',
	basedGodAddress: '0x4fc7e3249A149c0bf729863f49cD2FF468F2412F',
	decimals: 18,
	defaultGasPrice: 190,
	nftAirdrop: '0xb61c5BaAC8e813EF32672A36a6F26989DF3c6c45',
	nftToken: '0x500fc6caB650BAF0847aEe3Ad5068152B5272Cc4',
	weth: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  	susd: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',

};

export default config;

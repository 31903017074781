import React from "react";
import { withStyles } from '@material-ui/core/styles';
import {
  CircularProgress
} from '@material-ui/core';

import { withNamespaces } from 'react-i18next';

import {
  useWeb3React,
} from "@web3-react/core";

import {
  CONNECTION_CONNECTED
} from '../../constants'

import {
  IButton
} from '../pieces'

import Store from "../../stores";

const {emitter, store} = Store

const styles = theme => ({
  walletButtonCommon: {
    padding: '16px 8px 16px 8px',
    backgroundColor: 'transparent',
    borderRadius: '1rem',
    border: '1px solid #E1E1E1',
    
    fontSize: '0.6vw',
    display: 'flex',
    justifyContent: 'space-between',
  },
  walletButton: {
    width: '15vw',
    height: '4vw',
    margin: 'auto',
    marginBottom: '7%'
  },

  walletButtonSmall: {
    width: '200px',
  },

  buttonTxtCommon: {
    fontWeight: 500,
    margin: '0px 12px',
    color: 'rgb(1, 1, 1)',
    fontFamily: "'VT323', monospace",
  },
  buttonTxtSmall: {
    fontSize: '1rem',
  },
  buttonTxt: {
    fontSize: '1.6vw',
  },

  holder: {
    columnCount: 3,
    alignItems: 'center',
    width: '100%',
  },
  holderSmall: {
    margin: 'auto',
    columnCount: 1,
  },
  buttonHolderSmall: {
    marginTop: '5px',
  },
  logoCommon: {
     position: 'absolute',
  },
  logo: {
    width: '2vw',
    height: '2vw',
    right: '1.5vw',
  },
  logoSmall:{
    right: '20px',
    width: '30px',
    height: '30px'
  }
});

function PopUp (props) {
    const context = useWeb3React();
    const localContext = store.getStore('web3context');
    var localConnector = null;
    if (localContext) {
      localConnector = localContext.connector
    }
    const {
      connector,
      library,
      account,
      activate,
      deactivate,
      active
    } = context;
    var connectorsByName = store.getStore('connectorsByName')

    const { isSmall, closeModal, t, classes } = props

    const [activatingConnector, setActivatingConnector] = React.useState();
    React.useEffect(() => {
      if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined);
      }
    }, [activatingConnector, connector]);

    React.useEffect(() => {
      if (account && active && library) {
        store.setStore({ account: { address: account }, web3context: context })
        emitter.emit(CONNECTION_CONNECTED)
      }
    }, [account, active, closeModal, context, library]);

    return (
    <div className={isSmall? classes.holderSmall : classes.holder}>
      {Object.keys(connectorsByName).map(name => {
        const currentConnector = connectorsByName[name];
        const activating = currentConnector === activatingConnector;
        const connected = (currentConnector === connector||currentConnector === localConnector);

        var url;
        var display = name;
        if (name === 'MetaMask') {
          url = require('../../assets/icn-metamask.svg')
        } else if (name === 'WalletConnect') {
          url = require('../../assets/walletConnectIcon.svg')
        } else if (name === 'TrustWallet') {
          url = require('../../assets/trustWallet.png')
        } else if (name === 'Portis') {
          url = require('../../assets/portisIcon.png')
        } else if (name === 'Fortmatic') {
          url = require('../../assets/fortmaticIcon.png')
        } else if (name === 'Ledger') {
          url = require('../../assets/icn-ledger.svg')
        } else if (name === 'Squarelink') {
          url = require('../../assets/squarelink.png')
        } else if (name === 'Trezor') {
          url = require('../../assets/trezor.png')
        } else if (name === 'Torus') {
          url = require('../../assets/torus.jpg')
        } else if (name === 'Authereum') {
          url = require('../../assets/icn-aethereum.svg')
        } else if (name === 'WalletLink') {
          display = 'WalletLink'
          //display = 'Coinbase Wallet'
          url = require('../../assets/coinbaseWalletIcon.svg')
        } else if (name === 'Frame') {
          return ''
        }

        return (
          <div className={isSmall? classes.buttonHolderSmall : classes.buttonHolder} key={name}>
            <IButton
              img='/btn-lightgrey.png'
              className={`${classes.walletButtonCommon} ${isSmall? classes.walletButtonSmall : classes.walletButton}`}

              onClick={() => {
                props.onConnectionClicked(currentConnector, name, setActivatingConnector, activate)
              }}>
              <span
                className={`${classes.buttonTxtCommon} ${isSmall? classes.buttonTxtSmall : classes.buttonTxt}`}>
                { display }
              </span>

              { (!activating && !connected) && <img className={`${classes.logoCommon} ${isSmall? classes.logoSmall : classes.logo}`} src={url} alt=""/> }
              { activating && <CircularProgress size={ 15 } style={{marginRight: '10px'}} /> }
              { (!activating && connected) && <div style={{ background: '#4caf50', borderRadius: '10px', width: '10px', height: '10px', marginRight: '10px' }}></div> }
            </IButton>
          </div>
        )
      }) }

      <div className={isSmall? classes.buttonHolderSmall : classes.buttonHolder}>
        <IButton
          img='/btn-lightgrey.png'
          className={`${classes.walletButtonCommon} ${isSmall? classes.walletButtonSmall : classes.walletButton}`}
          onClick={() => { props.onDeactivateClicked(deactivate, connector); }}>
          <span className={`${classes.buttonTxtCommon} ${isSmall? classes.buttonTxtSmall : classes.buttonTxt}`}

            style={ {
              color: '#DC6BE5'
            } }>
            { t('Unlock.Deactivate') }
          </span>
        </IButton>
      </div>
    </div>
  )
  }

export default withNamespaces()(withStyles(styles)(PopUp));

import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';

import Unlock from './unlock.jsx';
import Media from 'react-media';

const styles = theme => ({
  modal: {
    position: 'absolute',
    top: 0,bottom:0,left:'15vw',right:0,
    zIndex: 26,
    width: '70vw',
  },
  modalSmol: {
    position: 'absolute',
    top: 0,bottom:0,right:0,left:0,
    zIndex: 26,
    width: '100vw',
    margin:'auto',
  },
  backgroundImage: {
    position: 'absolute',
    width: '70vw',
    left: 0,
    top: '5vh',
    zIndex: -31,
  },
  unlock: {
    position: 'absolute',
    paddingTop:'9vw',
    top: '5vh',
    width: '65vw',
    left: '2.5vw',
  },
  unlockSmall: {
    position: 'relative',
    width: '70%',
    margin:'auto',
  },
  closer: {
    position:'fixed',
    top:0,
    left:0,
    width: '100vw',
    height: '100vh',
    zIndex: -32,
  },
  titleCommon: {
    fontFamily: "'VT323', monospace",
    color: "#fff",
    width: '100%',
    zIndex: 3,
  },
  titleSmall: {
    fontSize: '2rem',
  },
  title: {
    fontSize: '4vw',
  }
});

class UnlockModal extends Component {

  _render(isSmall){
    const { classes, closeModal } = this.props;
    if(isSmall){
      return(
        <div className={classes.modalSmol}>
          <h1 className={`${classes.titleCommon} ${classes.titleSmall}`}>SELECT YOUR WALLET</h1>
          <Unlock className={`${classes.unlockSmall}`} isSmall={isSmall} closeModal={ closeModal } />
        </div>
      )
    }
    else{
      return(
        <div className={classes.modal}>
          <div className={classes.closer} onClick={closeModal}/>
          <h1 className={`${classes.titleCommon} ${classes.title}`} >SELECT YOUR WALLET</h1>
          <img src='/connect.png' alt='connect' className={classes.backgroundImage}/>
          <Unlock className={classes.unlock} isSmall={isSmall} closeModal={ closeModal } />
        </div>
      )
    }
  }
  render() {
    return (
      <Media queries={{
        small: "(max-width: 799px)",
        large: "(min-width: 800px)"
      }}>
      {matches => (
        <Fragment>
          {matches.small && this._render(true)}
          {matches.large && this._render(false)}
        </Fragment>
      )}
      </Media>   
    )
  };
}
export default withStyles(styles)(UnlockModal);

import UFragments from './artifacts/UFragments.json';
import erc20 from './artifacts/erc20.json';
import basedGod from './artifacts/BasedGod.json';
import rover from './artifacts/rover.json';
import MoonBase from './artifacts/MoonBase.json';
import uv2Factory from './artifacts/UniswapV2Factory.json';
import uv2Pair from './artifacts/UniswapV2Pair.json'
import uv2Router from './artifacts/UniswapV2Router.json'
import nftAirdrop from './artifacts/MoonbaseNFTAirdrop.json'
import nftToken from './artifacts/MoonbaseTheGameNFT.json'

const abi = {
	erc20,
	moonBase: MoonBase.abi,
	basedGod,
	rover,
	nftAirdrop,
	nftToken:nftToken.abi,
	based: UFragments.abi,
	uniswapV2Factory: uv2Factory,
	uniswapV2Pair: uv2Pair,
	uniswapV2Router: uv2Router,
}

export default abi;

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  WButton,
  BTextInput,
} from '../pieces'

import {
  WITHDRAW_RETURNED,
  DEPOSIT_RETURNED,
} from '../../constants'

import Store from "../../stores";


const DECIMALS = 3

const styles = (theme) => ({
  actionText: {
    "&:hover": {
      cursor:'pointer',
    },
  },
  console: {
    position: 'relative',
    backgroundColor: "transparent",
    padding: '2vw 0 0 0',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column'
  },
  consoleScreen: {
    backgroundColor: 'transparent',
    padding: '4% 4% 4% 4%',
    width: '85%',
    height: '72%',
    borderRadius: '2px',
    margin: 'auto',
  },
  consoleScreenText: {
    marginTop:'0',
    marginBottom:'0',
    textAlign: 'left',
    color: '#FFF',
  },
  cTextMobile: {
    fontSize: '1.3rem',    
  },
  cTextFull: {
    fontSize: "1.6vw",
  },
  consoleButtonLeft:{
    marginRight: '0.25rem',
  },
  consoleButtonRight:{
    marginLeft: '0.25rem',
  },
  consoleButton: {
    fontSize: '1.3vw',
    minHeight: '2vw',
    width: '45%',
    fontFamily: "'VT323', monospace",
  },
  consoleButtonMobile: {
    fontSize: '1rem',
    minHeight: '30px',
    width: '45%',
  },
  consoleButtons: {
    flexGrow: '1',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "85%",
    margin: 'auto',

  },
  inputHodler: {
    display: "flex",
    justifyContent: "left",
    align: "left",
  },
  based: {
    textAlign: 'right',
  },
  textInput: {
    width: '60%',
  },
  backgroundImage: {
    width:'100%',
    height: '100%',
    position: 'absolute',
    top:0,
    right:0,
    zIndex: -1,
  }
});

const {emitter} = Store

class Console extends Component {
  constructor(props) {
    super();
    this.state = {
      scene: 'default',
    }

    this.handleChange = this.handleChange.bind(this);
    this.onDeposit = this.onDeposit.bind(this);
    this.onWithdraw = this.onWithdraw.bind(this);
    this.returnConsole = this.returnConsole.bind(this)
  }
  componentDidMount() {
    emitter.on(DEPOSIT_RETURNED, this.returnConsole);
    emitter.on(WITHDRAW_RETURNED, this.returnConsole)
  }

  componentWillUnmount() {
    emitter.removeListener(DEPOSIT_RETURNED, this.returnConsole);
    emitter.removeListener(WITHDRAW_RETURNED, this.returnConsole)
  }

  returnConsole() {
    this._scene("withdraw")
  }

  handleChange(event) {
    let val = parseFloat(event.target.value)
    switch(this.state.scene) {
      case 'deposit':
        this.setState({toDeposit:val, toDepositRaw: event.target.value})
        break;
      case 'withdraw':
        this.setState({toWithdraw:val, toWithdrawRaw: event.target.value})
        break;
      default:
    }
  }

  onDeposit() {
    this.props.onDeposit(this.state.toDeposit)
  }

  onWithdraw() {
    this.props.onWithdraw(this.state.toWithdraw)
  }

  render() {
    const { classes, className, width, height } = this.props;

    return (
      <div style={{width:width, height:height}} className={`${className} ${classes.console}`}>
        <img src='/monitor-main.png' alt='monitor' className={classes.backgroundImage}/>
        {this._renderScreen(width)}
        {this._renderButtons()}
      </div>
    );
  }

  _renderButtons() {
    const{ classes } = this.props;
    const { scene } = this.state;
    return (
      <div className={classes.consoleButtons}>
        {scene === 'default' && this._renderButtonRow("Deposit", ()=>{this._scene("deposit")}, "Withdraw", ()=>{this._scene("withdraw")})}
        {scene === 'deposit' && this._renderButtonRow("Deposit", ()=>{this.onDeposit()}, "Cancel", ()=>{this._scene("default")})}
        {scene === 'withdraw' &&  this._renderButtonRow("Withdraw", ()=>{this.onWithdraw()}, "Cancel", ()=>{this._scene("default")})}
      </div>
    )
  }

  _renderButtonRow(leftLabel, leftAction, rightLabel, rightAction) {
    const{ classes, disabled, isMobile } = this.props;
    return (
      <React.Fragment>
        <WButton
          disabled={disabled}
          onClick={leftAction}
          className={`${ isMobile ? classes.consoleButtonMobile : classes.consoleButton} ${classes.consoleButtonLeft}`}>{leftLabel}</WButton>

        <WButton
          disabled={disabled}
          onClick={rightAction}
          className={`${ isMobile ? classes.consoleButtonMobile : classes.consoleButton} ${classes.consoleButtonRight}`}>{rightLabel}</WButton>
      </React.Fragment>)
  }

  _renderScreen(width) {
    const { classes } = this.props;
    const { scene } = this.state;
    return (
      <div className={classes.consoleScreen}>
          {scene === 'default' && this._renderDefaultScreen(width)}
          {scene === 'deposit' && this._renderDepositScreen()}
          {scene === 'withdraw' && this._renderWithdrawScreen()}
      </div>)
  }

  _renderDefaultScreen(width) {
    const { classes, inWallet, apr, isMobile} = this.props
    const pClassName = `${ isMobile ? classes.cTextMobile : classes.cTextFull} ${classes.consoleScreenText}`
    return (
      <div>
          <p className={pClassName}>Wallet: {parseFloat(inWallet.getValue()).toFixed(DECIMALS)} {this._based()}</p>
         {this._staked(pClassName)}
          <p className={pClassName}>APY: {parseFloat(apr.getValue()).toFixed(DECIMALS)}%</p>
      </div>)
  }

  _staked(pClassName) {
    const {deposited, depositedBased} = this.props
    return(
      <div>
      <p className={pClassName}>Staked: {parseFloat(deposited.getValue()).toFixed(DECIMALS)} {this._mbbased()}  </p>
      <p className={pClassName}>Staked: {depositedBased && parseFloat(depositedBased.getValue()).toFixed(DECIMALS)} {this._based()}
      </p>
      </div>)
  }

  _based() {
    const{ classes, isMobile} = this.props
    return (<span className={`${ isMobile ? classes.cTextMobile : classes.cTextFull} ${classes.consoleScreenText} ${classes.based}`}> $BASED </span>)
  }

  _mbbased() {
    const{ classes, isMobile} = this.props
    return (<span className={`${ isMobile ? classes.cTextMobile : classes.cTextFull} ${classes.consoleScreenText} ${classes.based}`}> mbBASED </span>)
  }
  _updateDeposit(val) {
    this.setState({toDepositRaw:val,toDeposit:val})
  }

  _renderDepositScreen() {
    const{ classes, inWallet, deposited, isMobile } = this.props;
    const { toDepositRaw} = this.state
    const pClassName = `${isMobile? classes.cTextMobile : classes.cTextFull} ${classes.consoleScreenText}`
    return (
      <div>
          <p 
          className={`${pClassName} ${classes.actionText}`}
          onClick={()=>{this._updateDeposit(inWallet.getValue())}} >Wallet: {parseFloat(inWallet.getValue()).toFixed(DECIMALS).trim()} {this._based()}</p>
          <p className={pClassName}>Staked: {parseFloat(deposited.getValue()).toFixed(DECIMALS)} {this._mbbased()} </p>
          <div className={classes.inputHodler}>
          <BTextInput value={toDepositRaw} className={`${isMobile? classes.cTextMobile : classes.cTextFull} ${classes.textInput}`} onChange={this.handleChange}/>
          {this._based()}
          </div>
      </div>)
  }

  _updateWithdraw(val) {
    this.setState({toWithdrawRaw:val,toWithdraw:val})
  }

  _renderWithdrawScreen() {
    const{ inWallet, deposited, isMobile, classes } = this.props;
    const { toWithdrawRaw } = this.state
    const pClassName = `${isMobile? classes.cTextMobile : classes.cTextFull} ${classes.consoleScreenText}`
    return (
      <div>
         <p className={pClassName}>Wallet: {parseFloat(inWallet.getValue()).toFixed(DECIMALS)} {this._based()}</p>
          <p 
            onClick={()=>{this._updateWithdraw(deposited.getValue())}}
            className={`${pClassName} ${classes.actionText}`}>Staked: {parseFloat(deposited.getValue()).toFixed(DECIMALS)} {this._mbbased()}</p>
          <div className={classes.inputHodler}>
            <BTextInput value={toWithdrawRaw} className={`${isMobile? classes.cTextMobile : classes.cTextFull} ${classes.textInput}`} onChange={this.handleChange}/>
            {this._based()}
          </div>
      </div>)
  }


  _scene(scene) {
    this.setState({scene:scene})
  }
}

export default withStyles(styles)(Console);
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Cards from "./Cards"

import Store from "../../stores";

import {
  WError,
} from "../pieces"

import Console from "../console"

import Media from 'react-media';
import UnlockModal from '../unlock/unlockModal.jsx'
import SidePanel from './sidePanel';
import Widget from './widget';
import BackBoard from './backBoard';

import {
  ERROR,
  WITHDRAW,
  EXIT,
  WITHDRAW_RETURNED,
  EXIT_RETURNED,
  GET_BALANCES_RETURNED,
  DEPOSIT,
  DEPOSIT_RETURNED,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  CONFIGURE,
  CONFIGURE_RETURNED,
  GET_BALANCES,
  GET_APY_RETURNED,
  GET_APY,
  GET_PRICE_RETURNED,
  GET_PRICE,
  GET_NFT_BALANCE,
} from "../../constants";
import { injected } from "../../stores/connectors";

var bigDecimal = require('js-big-decimal');

const styles = (theme) => ({
  popUp: {
    position: 'absolute',
    zIndex: '100',
  },
  popUpMobile: {
    position: 'fixed',
    top: 0,
    zIndex: '100',
  },
  
  console: {
    fontFamily: "'VT323', monospace",
    position:'fixed',
    bottom: '20vw',
    left: '30vw',
    zIndex: 2,
  },
  sidepanel: {
    fontFamily: "'VT323', monospace",
    position:'fixed',
    bottom: '8vw',
    right: '25.5vw',
    zIndex: 2,
  },
  space: {
    zIndex: -20,
    top: '30vh',
  },
  widget: {
    fontFamily: "'VT323', monospace",
    zIndex: 2,
    position:'fixed',
    bottom: '3.5vmin',
    left: '44.5vw'
  },
  warningOverlay: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    zIndex: 20,
    backgroundColor: 'rgba(255,0,0,0.4)'
  },
  warningText:{
    fontFamily: "'VT323', monospace",
    color: '#FFF',
    fontSize: '5vw',
    width: '100%'
  },
  warning: {
    textAlign: 'center',
    width: '100vw',
    height: '30vw',
    position: 'absolute',
    top:0,bottom:0,left:0,right:0,
    marginTop: '10vh',
    zIndex: 21,
  },
  initializeButton: {
    fontFamily: "'VT323', monospace",
    color: '#FFF',
    fontSize: '5vw',
    padding: '0 2vw 0 2vw',
    backgroundImage: 'linear-gradient(red 80%, white);',
    border: 'none',
  },

  warningOverlaySmall: {
  },
  warningTextSmall:{
    fontSize: '8vw',
  },
  warningSmall: {
    width: '100vw',
    height: '30vw',
    position: 'absolute',
    top:0,bottom:0,left:0,right:0,
    marginTop: '10vh',
    zIndex: 21,
  },
  initializeButtonSmall: {
    fontSize: '12vw',
    padding: '0 2vw 0 2vw',
    width: '60vw',
    height: '20vw',
  },
  backboard: {
    position: 'fixed',
    bottom: 0,
    width: '100vw',
    left: 0,
  },
  stereo: {
    zIndex: 4,
    position: 'fixed',
  },
  cassette: {
    position: 'fixed',
    right: '2vw',
    bottom: '30vw',
    width: '15vmin',
    transform: 'rotate(20deg)',
    '&:hover': {
      'cursor':'pointer',
    },
  },

  consoleSmall: {
    fontFamily: "'VT323', monospace",
    zIndex: 2,
    margin: 'auto',
    marginTop: '3vh',
  },
  sidepanelSmall: {
    fontFamily: "'VT323', monospace",
    zIndex: 2,
    margin: 'auto',
    marginTop: '3vh',
  },
  widgetSmall: {
    fontFamily: "'VT323', monospace",
    zIndex: 2,
    margin: 'auto',
    marginTop: '3vh',
  },
});

const {emitter,dispatcher,store} = Store

class Dashboard extends Component {
  constructor(props) {
    super();

    require("./dashboard.css")
    this.state = {
      moonbase: store.getStore("moonbase"),
      based: store.getStore("based"),
      rovers: store.getStore("rovers"),
      roversData: store.getStore("rawRovers"),
      apy: store.getStore("apy"),
      disabled: store.getStore("disabled"),
      moonbaseTVL: 0,
      account: null,
      value: "options",
      balanceValid: false,
      basedPrice:1,
    };
    this.balancesReturned = this.balancesReturned.bind(this)
    this.showHash = this.showHash.bind(this)
    this.errorReturned = this.errorReturned.bind(this)
    this.connectionConnected = this.connectionConnected.bind(this)
    this.connectionDisconnected = this.connectionDisconnected.bind(this)
    this.apy = this.apy.bind(this)
    this.handlePriceInfo = this.handlePriceInfo.bind(this)
    
    
  }

  componentDidMount() {
    emitter.on(ERROR, this.errorReturned);
    emitter.on(DEPOSIT_RETURNED, this.showHash);
    emitter.on(WITHDRAW_RETURNED, this.showHash);
    emitter.on(EXIT_RETURNED, this.showHash);
    emitter.on(GET_BALANCES_RETURNED, this.balancesReturned);
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.on(CONFIGURE_RETURNED, this.configureReturned);
    emitter.on(GET_APY_RETURNED, this.apy);
    emitter.on(GET_PRICE_RETURNED, this.handlePriceInfo)
    dispatcher.dispatch({type:GET_PRICE,content:{token:"based-money"}})
    injected.isAuthorized().then(isAuthorized => {
      if (isAuthorized) {
        injected.activate()
        .then((a) => {
          store.setStore({ account: { address: a.account }, web3context: { library: { provider: a.provider } } })
          emitter.emit(CONNECTION_CONNECTED)
          console.log(a)
        })
        .catch((e) => {
          console.error(e)
        })
      } else {

      }
    });
  }

  componentWillUnmount() {
    emitter.removeListener(ERROR, this.errorReturned);
    emitter.removeListener(DEPOSIT_RETURNED, this.showHash);
    emitter.removeListener(WITHDRAW_RETURNED, this.showHash);
    emitter.removeListener(EXIT_RETURNED, this.showHash);
    emitter.removeListener(GET_BALANCES_RETURNED, this.balancesReturned);
    emitter.removeListener(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.removeListener(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.removeListener(CONFIGURE_RETURNED, this.configureReturned);
    emitter.removeListener(GET_APY_RETURNED, this.apy);
    emitter.removeListener(GET_PRICE_RETURNED, this.handlePriceInfo)
  }

  handlePriceInfo(price_info) {
    console.log({basedPrice:price_info.usd})
    this.setState({basedPrice:price_info.usd})
  }

  balancesReturned () {
    this.setState({
      moonbase: store.getStore("moonbase"),
      based: store.getStore("based"),
      rovers: store.getStore("rovers"),
      account: store.getStore("account"),
      moonbaseTVL: store.getStore('moonbaseTVL'),
      basedPrice: store.getStore('basedPrice'),
    })
  }

  apy() {
    this.setState({
      apy: store.getStore("apy"),
      roversData: store.getStore("rawRovers"),
    })
  }

  showHash = (txHash) => {
    this.setState({
      snackbarMessage: null,
      snackbarType: null,
      loading: false,
    });
    const that = this;
    setTimeout(() => {
      that.setState({ snackbarMessage: txHash, snackbarType: "Hash" });
    });
  }

  errorReturned = (error) => {
    this.setState({ loading: false, snackbarMessage: null, snackbarType: null });
    const that = this;
    setTimeout(() => {
      that.setState({
        snackbarMessage: error.toString(),
        snackbarType: "Error",
      });
    });
  }

  configureReturned() {
    dispatcher.dispatch({ type: GET_APY, content: {} })
    dispatcher.dispatch({ type: GET_BALANCES, content: {} })
    dispatcher.dispatch({ type: GET_NFT_BALANCE, content: {} })

    setInterval(()=>{
      dispatcher.dispatch({ type: GET_BALANCES, content: {} })
    },5000)
    setInterval(()=>{
       dispatcher.dispatch({ type: GET_APY, content: {} })
    },15000)

    setInterval(()=>{
      dispatcher.dispatch({ type: GET_NFT_BALANCE, content: {} })
    },5000)
  }

  connectionConnected = () => {
    this.setState({ account: store.getStore('account') })
    dispatcher.dispatch({ type: CONFIGURE, content: {} })
  }

  connectionDisconnected = () => {
    this.setState({ account: store.getStore('account') })
  }

  render() {
    return (
        <Media queries={{
          small: "(max-width: 799px)",
          large: "(min-width: 800px)"
        }}>
          {matches => (
            <Fragment>
              {matches.small && this._render(true)}
              {matches.large && this._render(false)}
            </Fragment>
          )}
        </Media> 
    )
  }


  _render(isSmall) {
    if( isSmall) {
      return this._renderSmall()
    }
    return (
      <div>
      {this._renderExtras(isSmall)}
      {this._renderUpper(isSmall)}
      {this._renderLower(isSmall)}
      </div>)
  }

  _renderUpper(isSmall) {
    const { classes } = this.props;
    return (
      <div className={classes.spaceView}>
      <div className={`stars ${classes.space}`}/>
      </div>)
  }
  _renderLower(isSmall) {
    const { classes } = this.props;
    const  deposited  = this.state.moonbase.balance;
    const { based } = this.state

    const inWallet = based.balance
    return (
      <div>
        
        <BackBoard
        rightDial={{
          min:0,
          max:parseFloat(based.totalSupply.getValue()), 
          val:this.state.moonbaseTVL}}
        leftDial={{
          min:0,
          max:1,
          val:(parseFloat(deposited.add(inWallet).getValue()) > 0 )? parseFloat(deposited.divide(deposited.add(inWallet)).getValue()) : 0}}
        height='35vmin'
        className={classes.backboard}/>
        {this._renderDash(isSmall)}
      </div>)
  }

  _renderDash(isSmall) {
    const { classes } = this.props;
     console.log({tvl:this.state.moonbaseTVL, price:this.state.basedPrice})
    return (
    <div>
    <Cards></Cards>
    <Console
        disabled={this.state.disabled}
        width='25vw'
        height='18vw'
        className={classes.console}
        inWallet={this.state.based.balance}
        deposited={this.state.moonbase.balance}
        depositedBased={this.state.moonbase.basedBalance}
        apr={this.state.apy}
        onDeposit={this.onDeposit.bind(this)}
        onWithdraw={this.onWithdraw.bind(this)}/>
      <SidePanel
        textFontSize='1.1vw'
        titleFontSize='1.5vw'
        basedPrice={this.state.basedPrice}
        tvl={this.state.moonbaseTVL}
        roversData={this.state.roversData}
        height='28vw'
        width='17vw'
        className={classes.sidepanel}
        rovers={this.state.rovers}/>
      <Widget
        changeAccount={()=>{this.openModal()}}
        height='14.7vw'
        width='9.5vw'
        fontSize='1.5vw'
        account={this.state.account}
        className={classes.widget}
      />
      <img onClick={()=>{this.openModal()}} alt='casset' className={classes.cassette} src='/casset.gif'/>
      
    </div>
         
      )
  }

  _renderNoWallet(isMobile) {
    const { classes } = this.props;
    if(isMobile) {
      return (<div className={`${classes.warning} ${classes.warningSmall}`}>
        <p className={`${classes.warningText} ${classes.warningTextSmall}`} >WARNING CAPTAIN: </p>
        <p className={`${classes.warningText} ${classes.warningTextSmall}`} >NO WALLET CONNECTION DETECTED</p>
        <button 
          className={`${classes.initializeButton} ${classes.initializeButtonSmall}`} 
          onClick={()=>{this.openModal()}}>INITIALIZE</button>
        </div>)
    }
    return (
      <div className={classes.warning}>
        <p className={classes.warningText} >WARNING CAPTAIN: </p>
        <p className={classes.warningText} >NO WALLET CONNECTION DETECTED</p>
        <button 
          className={classes.initializeButton} 
          onClick={()=>{this.openModal()}}>INITIALIZE</button>
        </div>
  ) 
  }

  _renderExtras(isMobile) {
    if(!this.state.modalOpen && this.state.account) return null;


    const { classes } = this.props;
    return (
      <div>
      {!this.state.account && (<div className={classes.warningOverlay}></div>)}
        {!this.state.account && !this.state.modalOpen &&  this._renderNoWallet(isMobile) }
        {this.state.modalOpen && this.renderModal(isMobile)}
      </div>
    )
  }

  _renderSmall() {
    const { classes } = this.props;
    // Allow for scrolling on mobile
    document.getElementsByTagName("html")[0].style['overflow']="scroll"
    document.getElementsByTagName("html")[0].style['overflow-y']="scroll"
    document.getElementsByTagName("html")[0].style['overflow-x']="hidden"
    return (
      <div>
      {this._renderUpper(true)}
      {!this.state.modalOpen && this.state.account && (<div>
      <Console
        disabled={this.state.disabled}
        isMobile={true}
        width='80vw'
        height='50vw'
        className={classes.consoleSmall}
        inWallet={this.state.based.balance}
        deposited={this.state.moonbase.balance}
        apr={this.state.apy}
        onDeposit={this.onDeposit.bind(this)}
        onWithdraw={this.onWithdraw.bind(this)}/>
      <SidePanel
        textFontSize='4vw'
        titleFontSize='5vw'
        basedPrice={this.state.basedPrice}
        tvl={this.state.moonbaseTVL}
        roversData={this.state.roversData}
        height='120vw'
        width='80vw'
        className={classes.sidepanelSmall}
        rovers={this.state.rovers}/>
      <Widget
        changeAccount={()=>{this.openModal()}}
        height='80vw'
        width='60vw'
        fontSize='8vw'
        account={this.state.account}
        className={classes.widgetSmall}
      /></div>)}
      {this._renderExtras(true)}
      </div>
    )
  }

  navigateInternal = (val) => {
    this.setState({ value: val });
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  }

  onDeposit = (val) => {
    let v = new bigDecimal(val)
    v = v.multiply(new bigDecimal(10 ** this.state.based.decimals))
    this.setState({ amountError: false, loading: true });
    dispatcher.dispatch({
      type: DEPOSIT,
      content: { amount: v },
    });
  }

  onWithdraw = (val) => {
    let v = new bigDecimal(val)
    v = v.multiply(new bigDecimal(10 ** this.state.based.decimals))
    this.setState({ amountError: false, loading: true});
    dispatcher.dispatch({ type: WITHDRAW, content: { amount: v } });
  }

  onExit = () => {
    this.setState({ loading: true });
    dispatcher.dispatch({ type: EXIT, content: { } });
  }

  renderSnackbar = (isMobile) => {
    const {classes} = this.props;
    var { snackbarType, snackbarMessage } = this.state;
    return (
      <WError
        isMobile={isMobile}
        className={classes.popUp} 
        snackbarMessage={snackbarMessage}
        snackbarType={snackbarType}/>)
  }

  renderModal = () => {
    const {classes} = this.props;
    return (
      <div className={classes.warning}>
       
      <UnlockModal closeModal={ this.closeModal } modalOpen={ this.state.modalOpen } />
        
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Dashboard));

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
	widget: {
		backgroundColor: '#1b161c',
		borderTop: '3px outset #124e69',
		borderLeft: '3px outset #124e69',
	},
	accountHolder: {
		marginTop: '3%',
		width: '100%',
		textAlign: 'center',
	},
	accountText: {
		color: '#fff',
	},
	basedLvlText: {
		color: '#fff',
	},
	basedLvlHolder: {
		textAlign: 'center',
	},
	changeHolder: {
		"&:hover": {
			cursor:'pointer',
		},
	},
});


class Widget extends Component {
	constructor(props) {
		super();
	}

	render() {
		const { classes, className, width, height, fontSize, changeAccount } = this.props;


		return (
			<div style={{width:width, height: height}} className={`${classes.widget} ${className}`}>
				<div className={`${classes.accountHolder}`}>
					<span style={{fontSize:fontSize}} className={`${classes.accountText}`}>{this.getAccountName()}</span>
				</div>
				<div className={`${classes.basedLvlHolder}`}>
					<p  style={{fontSize:fontSize}} className={`${classes.basedLvlText}`}> BASED LVL: {this.getBasedLevel()}</p>
				</div>

				<div className={`${classes.accountHolder} ${classes.changeHolder}`}>
					<p onClick={()=>{changeAccount()}} style={{fontSize:fontSize}} className={`${classes.accountText}`}>Change</p>
				</div>
			</div>
		);
	}
	validAccount() {
		return this.props.account && 
			this.props.account.address && 
			this.props.account.address.length > 10

	}
	getBasedLevel() {
		if(!this.validAccount()) {
			return -1
		}
		
		return parseInt(this.props.account.address.substr(0,10)) % 69
	}
	getAccountName() {
		const { account } = this.props
		if(!this.validAccount()) {
			return "No Account"
		}
		return account.address.substr(0,5)+"..."+account.address.substr(-3)
	}
	
}

export default withStyles(styles)(Widget);
import React, { Component } from "react";
import "./Cards.css"

import {
  GET_NFT_BALANCE_RETURNED,
  CLAIM_NFT_RETURNED,
  GET_NFT_BALANCE,
  CLAIM_NFT,
} from '../../constants'

// import Video from "../../../public/newcard.mp4"

import Store from "../../stores/store";

const {dispatcher, emitter, store} = Store

export default class Cards extends Component {
  constructor(props) {
    super();
    this.state = {
      nft: store.getStore("nft"),
      displayState: 'balance',
      uri: undefined,
    }
    this.onNewCardResponse = this.onNewCardResponse.bind(this)
    this.resetDisplayState = this.resetDisplayState.bind(this)
    this.updateBalance = this.updateBalance.bind(this)
  }

  componentDidMount(){
    emitter.on(GET_NFT_BALANCE_RETURNED, this.updateBalance)
    emitter.on(CLAIM_NFT_RETURNED, this.onNewCardResponse)
  }

  componentWillUnmount() {
    emitter.removeListener(GET_NFT_BALANCE_RETURNED, this.updateBalance)
    emitter.removeListener(CLAIM_NFT_RETURNED, this.onNewCardResponse)
  }

  updateBalance(){
    this.setState({nft:store.getStore("nft")})
  }

  onNewCardSubmit(){
    this.setState({buttonDisabled: true, displayState: "loading"})
    dispatcher.dispatch({type:CLAIM_NFT})
  }

  onNewCardResponse(payload){
    //setup a success fail countdown
    //UI is updated to display success/fail
    //once timer countsdown the UI resets
    //
    if(payload.err) {
      this.setState({displayState: "fail"})
      this.resetDisplayState()
    }else{
      this.setState({displayState: "success"});
      this.setState({uri:payload.uri})
      this.resetDisplayState()
    }
    dispatcher.dispatch({type:GET_NFT_BALANCE})
    setTimeout(this.reset.bind(this), 6000)
  }

  reset() {
    this.setState({uri: undefined})
  }

  resetDisplayState(){
    this.setState({displayState: "balance"});
  }

  onSuccessFailTimeout(){
    this.updateBalance()
  }
  
  newCardSuccess(payload){
    //update UI to reflect the card from the response
    this.setState({displayState: "success"});
    this.resetDisplayState();
  }

  newCardFail(){
    //display some kind of error message, in the 
    this.setState({displayState: "fail"});
    this.resetDisplayState();
  }

  renderBalance(balance){
    return (
      <React.Fragment>
        <div className="hudText">Claimable Balance:</div>
        <div className="hudText">{balance}</div>
      </React.Fragment>
    )
  }
  
  renderLoading(){
    //animate text display
    return (
      <div className="hudText">claiming.......</div>
    )
  }

  renderFail(){
    //animate text display
    return (
      <div className="hudText">Transaction failed</div>
    )
  }

  renderSuccess(){
    //animate text display
    return (
      <div className="hudText">Transaction succeeded</div>
    )
  }

  render() {
    if(this.state.nft.balance == 0 && this.state.uri == undefined) {
      return <div></div>
    }
    let renderState;
    if(this.state.displayState === "loading") renderState = this.renderLoading
    else if (this.state.displayState === "success") renderState = this.renderSuccess
    else if (this.state.displayState === "fail") renderState = this.renderFail
    else renderState = this.renderBalance

    return (
      <div className="cardGameWindow">
        <div onClick={this.onNewCardSubmit.bind(this)}>
       <img alt='card' className="cardAnimation" src={this.state.uri? this.state.uri: '/anewcard.gif'} />

        </div>
        <div className="hud">
          {
            renderState(this.state.nft.balance)
          }
        </div>
        
      </div>
    );
  }
}
import async from 'async';
import {
	CONFIGURE_RETURNED,
	GET_BALANCES_RETURNED,
	ERROR,
	GET_TOTAL_VALUE_LOCKED_RETURNED,
	EXIT_RETURNED,
	DEPOSIT_RETURNED,
	WITHDRAW_RETURNED,
	GET_PRICE_RETURNED,
	GET_APY_RETURNED,
	CLAIM_NFT_RETURNED,
	GET_NFT_BALANCE_RETURNED,
} from '../constants';

import Web3 from 'web3'

import helpers from './helpers.jsx'

import emitter from "./emitter.jsx"

var http = require('http');

var bigDecimal = require('js-big-decimal');

const rp = require('request-promise');

const ethers = require('ethers')


const configure = async (store, payload) => {
	const web3 = new Web3(store.getStore('web3context').library.provider);
	http.get('https://raw.githubusercontent.com/cryptoghoulz/rovers/main/rovers.json',  (res) =>{
		const { statusCode } = res;

		let error;
		// Any 2xx status code signals a successful response but
		// here we're only checking for 200.
		if (statusCode !== 200) {
			error = new Error('Request Failed.\n' +
		                  `Status Code: ${statusCode}`);
		} 
		if (error) {
			console.error(error.message);
			// Consume response data to free up memory
			res.resume();
			return;
		}

		res.setEncoding('utf8');
		let rawData = '';
		res.on('data', (chunk) => { rawData += chunk; });
		res.on('end', () => {
			try {
				const parsedData = JSON.parse(rawData);
				store.setStore({ rovers: parsedData })
			} catch (e) {
				console.error(e.message);
			}
			
		});
	})

	const currentBlock = await web3.eth.getBlockNumber()

	store.setStore({ currentBlock: currentBlock })

	window.setTimeout(() => {
	  emitter.emit(CONFIGURE_RETURNED)
	}, 100)
}

const getBalances = async (store) => {
	console.log("getBalances called")
	const account = store.getStore('account')
	const moonbase = store.getStore('moonbase')
	const token = store.getStore('based')

	const web3 = new Web3(store.getStore('web3context').library.provider);
	 async.parallel([
	  (cbInner) => {
	  	
		helpers.getERC20Balance(web3, token, account, (err, balance)=>{
			if(err) {
				console.error(err)
				cbInner(err)
				return
			}
			cbInner(err, balance.divide(new bigDecimal(10**token.decimals),token.decimals))
		})
	  },

	  (cbInner) => {
	  	
		helpers.getTotalSupply(web3, token, account, (err, balance)=>{
			if(err) {
				console.error(err)
				cbInner(err)
				return
			}
			cbInner(err, balance.divide(new bigDecimal(10**token.decimals),token.decimals))
		})
	  },

	  (cbInner) => {
	  	
		helpers.getERC20Balance(web3, token, moonbase, (err, balance)=>{
			if(err) {
				console.error(err)
				cbInner(err)
				return
			}
			let basedCount = balance.divide(new bigDecimal(10**token.decimals),token.decimals);
			cbInner(err, basedCount)
		})
	  },
	  (cbInner) => {
	  	const token = store.getStore('based')
		helpers.getMoonbaseBalance(web3, moonbase, account, (err, balance)=>{
			if(err != null) {
				console.error(err)
				cbInner(err)
				return
			}
			let basedBalance = balance.based
			let mbBasedBalance = balance.token
			moonbase.balance = mbBasedBalance.divide(new bigDecimal(10**token.decimals),token.decimals);
			moonbase.basedBalance = basedBalance.divide(new bigDecimal(10**(token.decimals*2)),token.decimals);
			cbInner(null, moonbase)
		})
	  },
	], (err, data) => {
		
		if(err) {
			console.error({err,data})
			return emitter.emit(ERROR, err);
		}
		
		emitter.emit(GET_BALANCES_RETURNED)
		if(data[0]) {
			token.balance = data[0]
		}
		if(data[1]) {
			token.totalSupply = data[1]
		}
		
		
		store.setStore({
			based: token,
			moonbaseTVL: parseFloat(data[2].getValue()),
			moonbase: data[3],
		})
	})
	
}

const getRovers = async (store) => {
	const account = store.getStore('account')
	const basedGod = store.getStore('basedGod')
	const web3 = new Web3(store.getStore('web3context').library.provider);

	let basedGodContract = new web3.eth.Contract(basedGod.abi, basedGod.address)
	let rovers = await basedGodContract.methods.getRovers().call({from:account.address})
	async.map(rovers, (rover, callback)=>{
		try{
			let token = helpers.getRoverToken(web3, account)

			callback(null,{
				earned:1000, //TODO
				received:0,
				withdrawn:0,
				address: rover,
				token: token,
			})
		}catch(err){
			callback(err)
		}

	},(err, roverData) => {
		if(err) {
		return emitter.emit(ERROR, err);
	  	}
	  	store.setStore({rovers:roverData})
	})

}

const getTVL = async (store) => {
	const moonbase = store.getStore('moonbase')
	const account = store.getStore('account')
	const web3 = new Web3(store.getStore('web3context').library.provider)
	helpers.getMoonbaseTVL(web3, account, moonbase, (err, tvl)=>{
		moonbase.tvl = tvl
		store.setStore({moonbase:moonbase})
		emitter.emit(GET_TOTAL_VALUE_LOCKED_RETURNED)
	})
}


const deposit = async (store, payload) => {
	console.log({fn:"deposit",payload})
	const moonbase = store.getStore('moonbase')
	const account = store.getStore('account')
	const web3 = new Web3(store.getStore('web3context').library.provider)
	const moonbaseContract = new web3.eth.Contract(moonbase.abi, moonbase.address)
	const based = store.getStore('based')
	const gasPrice = await helpers.getGasPrice()
	helpers.checkApproval(web3, based, account, payload.amount.getValue(), moonbase.address, (err)=>{
		if(err != null) {
			emitter.emit(ERROR, err);
			return
		}
		moonbaseContract.methods.deposit(payload.amount.getValue()).send({ from: account.address, gasPrice: web3.utils.toWei(gasPrice, 'gwei') },(err, txhash)=>{

				if(err != null) {
					emitter.emit(ERROR, err);
					return
				}
				emitter.emit(DEPOSIT_RETURNED)
		})
	})
	
}

const withdraw = async (store, payload) => {
	const moonbase = store.getStore('moonbase')
	const account = store.getStore('account')
	const web3 = new Web3(store.getStore('web3context').library.provider)
	const moonbaseContract = new web3.eth.Contract(moonbase.abi, moonbase.address)

	moonbaseContract.methods.withdraw(payload.amount.getValue()).send({ from: account.address, gasPrice: web3.utils.toWei(await helpers.getGasPrice(), 'gwei') },(err, txhash)=>{

			if(err != null) {
				emitter.emit(ERROR, err);
				return
			}
			emitter.emit(WITHDRAW_RETURNED)
	})
}

const exit = async (store, payload) => {
	const moonbase = store.getStore('moonbase')
	const account = store.getStore('account')
	const web3 = new Web3(store.getStore('web3context').library.provider)
	const moonbaseContract = new web3.eth.Contract(moonbase.abi, moonbase.address)

	moonbaseContract.methods.exit().send({ from: account.address, gasPrice: web3.utils.toWei(await helpers.getGasPrice(), 'gwei') },(err, txhash)=>{

			if(err != null) {
				emitter.emit(ERROR, err);
				return
			}
			emitter.emit(EXIT_RETURNED)
	})
}

const updateRawRovers = async (store) => {
	try{
		const basedGod = store.getStore('basedGod')
		const tvl = store.getStore('moonbaseTVL')
		if(tvl === 0) {
			return
		}
		const web3 = new Web3(store.getStore('web3context').library.provider);
		const roverAddrs = await helpers.getRovers(web3, basedGod)
		let rovers = {}
		console.log({roverAddrs})
		let yearlyTotal = new bigDecimal('0')
		await async.map(roverAddrs, (addr, callback)=> {
			helpers.getRoverYields(web3, addr, (err, yields)=>{
				rovers[addr.toLowerCase()] = yields
				yearlyTotal = yearlyTotal.add(yields.yearly)
				callback()
			})
		})
		console.log({yearlyTotal})
		
		let apy = yearlyTotal.divide(new bigDecimal(tvl)).multiply(new bigDecimal('100'))
		store.setStore({
			apy: apy ,
			rawRovers:rovers})
		emitter.emit(GET_APY_RETURNED)
		return
	}catch(e){
		console.error(e)
		emitter.emit(ERROR, e);
		return
	}
}

const getPrice = async (store, payload) => {
	const coingecko = store.getStore('coingecko')
	try{
		const result = await coingecko.coins.fetch(payload.token)
		if (payload.token === 'based-money') {
			store.setStore({basedPrice:result.data.market_data.current_price.usd})
		}
		emitter.emit(GET_PRICE_RETURNED, result.data.market_data.current_price)
	}catch(err) {
		console.error(err)
		emitter.emit(ERROR, err)
		return
	}
}

const getNFTBalance = async (store) => {
	const account = store.getStore('account')
	const nft = store.getStore('nft')
	const web3 = new Web3(store.getStore('web3context').library.provider)
	const nftContract = new web3.eth.Contract(nft.abi, nft.address)
	try{
		let balance = await nftContract.methods.nftPerAddress(account.address).call({})
		console.log({type:"nft",balance, address:account.address})
		nft.balance = balance
		store.setStore({nft})
	}catch(err){
		console.error(err)
		emitter.emit(ERROR,err)
	}
	emitter.emit(GET_NFT_BALANCE_RETURNED)
	
}

const stupidStuffBecauseWeb3GetURI = async (store,id) =>{ 
	const nft = store.getStore('nft')
	const signer = (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
	const tokenContract = new ethers.Contract(nft.tokenAddress, nft.tokenAbi, signer);
	for(let i = 0; i < 50; i++){
		try{
			//console.log({id})
			let uri = await tokenContract.tokenURI(id)
			//console.log({uri})
			// let uri = 'https://ipfs.io/ipfs/QmRFbqeunFaznbzA8RDyQKzo8RpEVTptKSS8F2twH74atf'
			uri = uri.replace('ipfs://','https://ipfs.io/')
			const nftData = JSON.parse(await rp(uri))
			//console.log({nftData})
			return nftData.image.replace('ipfs://','https://ipfs.io/')
		}catch(ignored){

		}
	}
	throw new Error("could not get your token")
}

const claimNFT = async (store) => {
	const nft = store.getStore('nft')
	const account = store.getStore('account')
	const web3 = new Web3(store.getStore('web3context').library.provider)
	const nftContract = new web3.eth.Contract(nft.abi, nft.address)

	try{
		let receipt = await nftContract.methods.claimMoonbaseNFT().send({ from: account.address, gasPrice: web3.utils.toWei(await helpers.getGasPrice(), 'gwei') })
		const id = web3.utils.hexToNumberString(receipt.events["0"].raw.topics[3])
		emitter.emit(CLAIM_NFT_RETURNED,{uri:await stupidStuffBecauseWeb3GetURI(store,id)})
	}catch(err){
		console.error(err)
		emitter.emit(ERROR,err)
		emitter.emit(CLAIM_NFT_RETURNED,{err})
	}
	
}


export default {
	configure,
	getBalances,
	getRovers,
	getTVL,
	deposit,
	withdraw,
	exit,
	updateRawRovers,
	getPrice,
	getNFTBalance,
	claimNFT
};
export const CONNECTION_CONNECTED = 'CONNECTION_CONNECTED'
export const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED'

export const ERROR = 'ERROR'

export const CONFIGURE = 'CONFIGURE'
export const CONFIGURE_RETURNED = 'CONFIGURE_RETURNED'


export const GET_REWARDS = 'GET_REWARDS'
export const GET_REWARDS_RETURNED = 'GET_REWARDS_RETURNED'

export const EXIT = 'EXIT'
export const EXIT_RETURNED = 'EXIT_RETURNED'

export const WITHDRAW = 'WITHDRAW'
export const WITHDRAW_RETURNED = 'WITHDRAW_RETURNED'

export const DEPOSIT = 'DEPOSIT'
export const DEPOSIT_RETURNED = 'DEPOSIT_RETURNED'

export const GET_BALANCES = 'GET_BALANCES'
export const GET_BALANCES_RETURNED = 'GET_BALANCES_RETURNED'

export const GET_BALANCES_PERPETUAL = 'GET_BALANCES_PERPETUAL'
export const GET_BALANCES_PERPETUAL_RETURNED = 'GET_BALANCES_PERPETUAL_RETURNED'

export const GET_TOTAL_VALUE_LOCKED_RETURNED = 'GET_TOTAL_VALUE_LOCKED_RETURNED'
export const GET_TOTAL_VALUE_LOCKED = 'GET_TOTAL_VALUE_LOCKED'

export const GET_ROVERS = 'GET_ROVERS'
export const GET_ROVERS_RETURNED = 'GET_ROVERS_RETURNED'

export const GET_APY = 'GET_APY'
export const GET_APY_RETURNED = 'GET_APY_RETURNED'

export const GET_PRICE = 'GET_PRICE'
export const GET_PRICE_RETURNED = 'GET_PRICE_RETURNED'

export const GET_NFT_BALANCE = 'GET_NFT_BALANCE'
export const GET_NFT_BALANCE_RETURNED = 'GET_NFT_BALANCE_RETURNED'

export const CLAIM_NFT = 'CLAIM_NFT'
export const CLAIM_NFT_RETURNED = 'CLAIM_NFT_RETURNED'